*,
::before,
::after {
  box-sizing: border-box;
  transition: 0.3s;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  --red: #c60b1e;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #828d9e;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--red);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--red);
}

button,
[role="button"] {
  cursor: pointer;
}

.appContainer {
  max-width: 1250px;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .appContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
