.container {
  display: grid;
  grid-template-columns: 6fr 5fr;
  min-height: 100vh;
}
.imgSec {
  height: 100%;
  background-image: url(../assets/images/backgroundImg.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contentSec {
  padding: 2.5rem 2rem;

  .logo {
    margin-bottom: 9rem;
    width: 65px;
  }

  .logo2 {
    width: 120px;
  }

  .ttl {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.5;
    max-width: 500px;
    margin-bottom: 1.5rem;
    span {
      color: var(--red);
    }
  }

  .txt {
    margin-bottom: 3rem;
    line-height: 1.5;
  }

  .inputWrap {
    display: flex;
    border: none;
    height: 44px;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    input {
      border: none;
      font-family: "Poppins", sans-serif;
      background: #f5f5f5;
      padding: 12px;
      width: calc(100% - 95px);
      max-width: 300px;
      outline: none;
    }

    button {
      background: #f15c6c;
      color: #fff;
      border: none;
      font-family: "Poppins", sans-serif;
      padding: 12px 16px;
      font-weight: 500;
    }

    .error {
      width: 100%;
      color: var(--red);
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .note {
    font-size: 12px;
  }
}

.toast {
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 300px;
  border-radius: 10px;
  padding: 1rem;

  &.success {
    background: #1d4b33;

    h3 {
      color: #2dc573;
    }
  }

  &.error {
    background: #351313;
    h3 {
      color: #f45858;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 4px;
  }

  p {
    color: #fff;
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }

  .contentSec {
    .logo {
      margin-bottom: 7rem;
    }
    .ttl {
      font-size: 52px;
      line-height: 1.3;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 5fr 6fr;
  }
}

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
  }

  .imgSec {
    display: none;
  }

  .contentSec {
    background-image: url(../assets/images/backgroundImg.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    background-blend-mode: overlay;
    background-color: #ffffff90;

    .logo {
      margin-bottom: 5rem;
    }

    .ttl {
      font-size: 44px;
      max-width: 350px;
    }

    .txt {
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 600px) {
  .contentSec {
    padding: 1rem;
    padding-top: 2rem;
  }
}
